import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import { appRoutes } from './constants/routes';

const Layout = lazy(() => import('./components/Core/Layout'));
const Home = lazy(() => import('./pages/Home/Home'));
const Configurator = lazy(() => import('./pages/Configurator/Configurator'));
const NotFound = lazy(() => import('./components/NotFound/NotFound'));

const App = () => {
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path={appRoutes.default} element={<Home />} />
          <Route path={appRoutes.configurator} element={<Configurator />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;